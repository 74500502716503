import "./App.css";
import React from "react";
import Header from "./components/Header";

function App() {
 

  return (
    <div className="App">
      <header className="App-header">
        <title>Jute's Cowboy Poetry</title>
      </header>
      <Header />
    </div>
  );
}

export default App;
