import React from "react";

const Def = ({ children})  => 
  
  (
    <main>
      <div className="App-Div">
          
        {children}
      
      </div>
    </main>
  );

export default Def