import React from "react";
import Def from "../components/Default";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import poems from "../poems";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";

export default function Poerty(props) {
  const renderedPoem = props.poems.map((data, i) => {
    return (
      <Container key={i}>
        <ListGroup>
          <ListGroupItem>Title:{data.title}</ListGroupItem>
          <ListGroupItem>Author:{data.author}</ListGroupItem>
          <ListGroupItem>Poem:{data.poem}</ListGroupItem>
        </ListGroup>
      </Container>
    );
  });
  return (
    <Def>
      <Container>
        <h1>Poerty Page</h1>
        <Container>
          <Card>
            <Card.Body>
              <Card.Text>{renderedPoem}</Card.Text>
            </Card.Body>
          </Card>
        </Container>
        <Container>
          <Button>Poem</Button>
        </Container>
      </Container>
    </Def>
  );
}
