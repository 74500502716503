import React from "react";
import Def from "../components/Default";

export default function About() {
  return (
    <main>
      <div className="App-Div">
        <div>
          <h1>About Me </h1>
          <p>
            Bacon ipsum dolor amet pork chop pork ham turkey, spare ribs beef
            buffalo pork loin rump brisket shankle. Porchetta sausage pork loin
            drumstick chislic. Sirloin jerky corned beef biltong leberkas,
            andouille short ribs ribeye bacon beef ribs. Shoulder frankfurter
            bresaola turkey sirloin venison t-bone meatloaf ham hock bacon
            prosciutto sausage pork chop. Pancetta jerky ball tip chuck.{" "}
          </p>
          <p>
            Chislic ham hock shoulder frankfurter, boudin chicken meatloaf
            tenderloin corned beef tri-tip filet mignon burgdoggen pastrami.
            Short ribs chicken chislic salami alcatra bacon meatloaf tri-tip
            buffalo pork brisket. Turducken jerky pork chop boudin alcatra short
            ribs beef ribs t-bone hamburger pork loin doner. Pork chop doner
            leberkas cow kielbasa, burgdoggen flank hamburger pork belly pork
            capicola turducken alcatra ribeye.
          </p>
          <p>
            Spicy jalapeno bacon ipsum dolor amet chicken frankfurter pig cupim
            doner turducken. Meatloaf turducken rump chuck, short ribs flank
            beef ribs t-bone pancetta kevin doner. Shankle venison sirloin beef.
            Meatball burgdoggen picanha pork belly jowl leberkas. Short ribs
            ribeye chuck ham jerky turducken andouille. Doner ham t-bone, pork
            short loin pig brisket buffalo alcatra bresaola. Tongue venison
            shoulder, jerky short loin alcatra t-bone drumstick. Landjaeger ham
            shank chicken porchetta ham hock, beef ribs shoulder ribeye salami
            cupim chuck jerky. Pork loin spare ribs drumstick strip steak, chuck
            t-bone turducken brisket pork chop. Ham hock tri-tip pastrami
            sirloin. Drumstick turducken fatback ham hock prosciutto doner pork
            loin chicken. Andouille spare ribs chuck boudin shoulder chislic.
            Shoulder landjaeger venison capicola, pork belly doner corned beef
            alcatra cupim tongue porchetta spare ribs bresaola. Ham rump turkey
            pastrami pork loin tongue beef ball tip sirloin shoulder strip
            steak.{" "}
          </p>
          <p>
            Bresaola porchetta frankfurter, hamburger ground round meatball
            leberkas picanha alcatra turducken turkey tenderloin cupim. Ball tip
            tri-tip hamburger meatloaf short ribs tenderloin. Kielbasa boudin
            doner tenderloin sausage. Bresaola landjaeger pork belly doner. Pig
            alcatra picanha shoulder t-bone drumstick meatball bacon tri-tip
            kielbasa jowl ribeye pork belly. Porchetta beef strip steak
            bresaola. Ball tip ham tri-tip, tail pork chop meatloaf turducken
            cupim.{" "}
          </p>
          <p>
            Biltong sirloin meatloaf, picanha chuck tri-tip tongue landjaeger
            andouille doner buffalo ground round. Ground round filet mignon
            short ribs pork belly porchetta kielbasa. Sausage spare ribs cow
            biltong chislic, jerky beef ribs jowl boudin. Bacon short loin
            pancetta rump kevin sausage. Brisket pork drumstick, corned beef
            frankfurter filet mignon tri-tip spare ribs fatback alcatra.
            Pancetta buffalo pastrami shoulder bacon, turkey salami chislic
            kielbasa. Fatback shankle ground round, flank bresaola pastrami
            chuck cupim doner leberkas andouille landjaeger tongue ham hock.
            Bacon beef biltong, pork corned beef strip steak ham hock meatball
            tongue pork loin doner. Does your lorem ipsum text long for
            something a little meatier? Give our generator a try… it’s tasty!
          </p>
        </div>
      </div>
    </main>
  );
}
