import  React from 'react';
import Def from '../components/Default';


export default function Home() {

    return (
       <Def>
            <h1>Jimmy's D's Cowboy Corner</h1>
            <p>  Get along little dogies !</p>


       </Def>
        
       
        
    )
}